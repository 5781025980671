/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 *
 * Messages.css
 */

.tl-messages {
  display: block;
  box-sizing: border-box;
  position: fixed;
  top: 190px;
  z-index: 3;
  max-width: 700px;
  width: 100%;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);
}

.tl-message {
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0;
  animation-name: tl-message-animation;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}


.tl-message-dismissed {
  animation-name: tl-message-dismiss-animation;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}


.tl-message p {
  display: block;
  box-sizing: border-box;
  position: relative;
  margin: 15px 20px;
  padding: 10px 32px 10px 20px;
  background: #fff;
  z-index: 1;
  border: solid 1px transparent;
  border-radius: 2px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  font-family: 'Roboto Slab', serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
}


.tl-message-info p {
  background: #ccc;
}


.tl-message-success p {
  background: #7af2ec;
  border-color: #00d0c6;
  color: #009a92;
}


.tl-message-warning p {
  background: #ff0;
}


.tl-message-error p {
  background: #ff81a2;
  border-color: #cd0032;
  color: #000;
}


button[type="button"].tl-message-dismiss-button {
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 7px;
  right: 26px;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  color: transparent;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.7;
  z-index: 2;
}

button[type="button"].tl-message-dismiss-button:hover {
  opacity: 1;
}

button[type="button"].tl-message-dismiss-button::after {
  display: block;
  content: '\00D7';
  box-sizing: border-box;
  position: absolute;
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  font-family: monospace;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  color: #000;
}


@keyframes tl-message-animation {
  0% {
    height: 0;
    opacity: 0;
    transform: translate(0, -100px);
  }
  5% {
    height: 40px;
    opacity: 0;
    transform: translate(0, -100px);
  }
  10% {
    height: 40px;
    opacity: 1;
    transform: translate(0, 0);
  }
  90% {
    height: 40px;
    opacity: 1;
    transform: translate(0, 0);
  }
  95% {
    height: 40px;
    opacity: 0;
    transform: translate(0, -100px);
  }
  100% {
    height: 0;
    opacity: 0;
    transform: translate(0, -100px);
  }
}


@keyframes tl-message-dismiss-animation {
  0% {
    height: 40px;
    opacity: 1;
    transform: translate(0, 0);
  }
  5% {
    height: 40px;
    opacity: 0;
    transform: translate(0, -100px);
  }
  10% {
    height: 0;
    opacity: 0;
    transform: translate(0, -100px);
  }
  100% {
    height: 0;
    opacity: 0;
    transform: translate(0, -100px);
  }
}
