/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-24
 *
 * Table.css
 */


.tl-table {
  display: table;
  box-sizing: border-box;
  position: relative;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border-left: solid 1px #ddd;
  border-right: solid 1px #ddd;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.tl-table-is-loading {
  opacity: 0.5;
}

.tl-table thead th {
  padding: 10px;
  background: #eee;
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
  text-align: left;
}

.tl-table tbody td {
  padding: 10px;
  border-bottom: solid 1px #ddd;
  text-align: left;
}

.tl-table tbody tr:hover td {
  background: rgba(200, 200, 200, 0.2);
}


.tl-table-details {
  display: table;
  box-sizing: border-box;
  position: relative;
  border-collapse: collapse;
  border-spacing: 0;
  width: 500px;
  margin: 50px auto 0 auto;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.tl-table-details tbody th,
.tl-table-details tbody td {
  padding: 8px 0;
  font-weight: inherit;
  text-align: left;
}

.tl-table-details tbody th {
  width: 150px;
}

.tl-table-details tbody td {
  padding-left: 6px;
}

.tl-table-details tfoot td {
  padding: 20px 0 0 0;
}
