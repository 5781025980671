html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
html,
body {
  font-family: Arial, verdana, sans-serif;
}
a,
a img {
  text-decoration: underline;
  border: none;
  padding: 0;
  margin: 0;
  color: black;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */
}
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNlCV0lP.ttf) format('truetype');
}
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNmlUElP.ttf) format('truetype');
}
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBIXvYC6trAT55ZBi1ueQVIjQTD-JqqFA.ttf) format('truetype');
}
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v15/qkBIXvYC6trAT55ZBi1ueQVIjQTDH52qFA.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoslab/v13/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoa4OWaA.ttf) format('truetype');
}
html, body {
  font-family: 'Karla', sans-serif;
  color: #424242;
}
h1,
h2,
h3 {
  font-family: 'Roboto Slab', sans-serif;
}
h4,
h5 {
  font-family: 'Karla', sans-serif;
}
h1 {
  font-size: 42px;
  margin-bottom: 30px;
}
h2 {
  font-size: 36px;
  margin-bottom: 30px;
}
h3 {
  font-size: 28px;
  margin-bottom: 30px;
}
.p-intro {
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 40px;
}
.p-intro2 {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 60px;
}
.p a {
  color: #FF0043;
}
.p a:hover {
  text-decoration: underline;
}
svg {
  width: 100%;
  height: 100%;
}
ol, ul {
  margin-bottom: 20px;
  padding-left: 50px;
  font-size: 16px;
  line-height: 24px;
}
ol li, ul li {
  margin-bottom: 10px;
}

.tl-app {
  display: flex;
  flex-direction: column;
}
.tl-app-main {
  flex: 1;
}
.tl-app-main > *:first-child {
  margin-top: 140px;
}
.faq-switch {
  margin-bottom: 30px;
}
.faq-switch__label {
  display: block;
  background-color: #f3f3f3;
  text-align: left;
  padding: 14px 20px;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  text-transform: uppercase;
  color: #424242;
  padding-right: 40px;
  line-height: 24px;
  font-weight: bold;
}
.faq-switch__label:after {
  content: "+";
  display: block;
  position: absolute;
  right: 8px;
  top: 8px;
  color: #00CFC5;
  font-size: 48px;
  line-height: 32px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-family: 'Karla', sans-serif;
}
.faq-switch__checkbox {
  display: none;
}
.faq-switch__answer {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  text-align: left;
}
.faq-switch__answer-content {
  padding: 15px 20px;
  background-color: white;
  z-index: 3;
  line-height: 30px;
  font-size: 15px;
}
.faq-switch__checkbox:checked ~ .faq-switch__answer {
  max-height: 500px;
}
.faq-switch__checkbox:checked ~ .faq-switch__label:after {
  content: "-";
}
.price h1 {
  margin-bottom: 15px;
}
.price h3 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}
.price h2 {
  font-size: 28px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}
.price__holder {
  border-radius: 15px;
  overflow: hidden;
  max-width: 730px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  box-shadow: 0 0 10px #0000002b;
}
.price__tier {
  flex: 1 1 50%;
  padding: 30px 40px;
  background-color: white;
  color: #424242;
  position: relative;
}
.price__tier:first-child:after {
  content: "";
  display: block;
  position: absolute;
  background-color: #b3b3b3;
  width: 1px;
  top: 30px;
  bottom: 30px;
  right: 0;
}
.price__level {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  font-family: monospace;
  font-size: 14px;
}
.price__border {
  flex: 1;
  border-bottom: 1px dashed #424242;
}
.price__names {
  padding-right: 5px;
}
.price__price {
  padding-left: 5px;
}
.price__title-description {
  display: block;
  font-size: 18px;
  line-height: 26px;
  font-family: 'Karla', sans-serif;
  text-align: center;
  margin-bottom: 15px;
}
.big-bullets {
  font-size: 16px;
  list-style-type: none;
  font-family: 'Karla', sans-serif;
}
.big-bullets strong {
  display: block;
  font-size: 26px;
  margin-bottom: 6px;
}
.big-bullets li {
  counter-increment: how-counter;
  margin-bottom: 20px;
  position: relative;
  padding-left: 68px;
  min-height: 50px;
  line-height: 24px;
  font-size: 16px;
}
.big-bullets li:before {
  content: counter(how-counter);
  background: #00CFC5;
  width: 50px;
  height: 50px;
  border-radius: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-family: 'Roboto Slab', sans-serif;
  color: white;
  margin-left: 0;
  top: 0;
  left: 0;
}
.big-bullets li:nth-child(3):before {
  background: #ff0043;
}
html, body, #root, .tl-app {
  height: 100%;
}
.header {
  background-color: white;
  height: 110px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
}
.header__inner {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 106px;
}
.header__logo {
  padding: 10px;
  display: block;
  width: 300px;
  height: 69px;
}
.header__nav {
  flex: 1 1 100%;
  width: 100%;
  height: 38px;
}
.header__slogan {
  color: white;
  font-size: 18px;
  margin-bottom: 0;
  background-color: #FF0043;
  padding: 5px 10px 13px 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
  box-shadow: 0 0 10px #0000002b;
  z-index: 3;
  min-width: 370px;
  align-self: flex-start;
}
.header__slogan:before {
  content: "";
  display: block;
  background-color: #00CFC5;
  position: absolute;
  width: 40px;
  height: 34px;
  top: 0;
  right: 100%;
  border-bottom-left-radius: 20px;
  z-index: 2;
}
.header__slogan:after {
  background-image: url('./img/globe_top.png');
  background-size: cover;
  content: "";
  display: block;
  height: 66px;
  width: 150px;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
}
.header__ul {
  list-style-type: none;
  display: flex;
  margin-top: 7px;
  justify-content: flex-end;
}
.header__link {
  display: block;
  padding: 5px 10px;
  text-decoration: none;
}
.header__link:hover{
  color: #568a99;
}
main {
  flex: 1;
}
.section__block {
  position: relative;
  background-color: white;
}
.section__block--gradient {
  background: #00cfc5;
  background: linear-gradient(135deg, #ff0043ff 0%, #00cfc5ff 100%);
  /* background: linear-gradient(135deg, #ff0043, #a90731 40%, #00cfc5 75%); */
  position: relative;
  color: white;
}
.section__block--gradient h1 {
  color: white;
}
.section__block--gradient:after {
  content: "";
  display: block;
  background-image: url(./img/globe2.png);
  background-size: contain;
  background-position: 90px 70px;
  background-repeat: no-repeat;
  width: 340px;
  height: 260px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 4;
}
.section__block--light-gray {
  background-color: #fafafa;
}
.section__block--intro {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 60px;
  min-height: 100vh;
}
.section__block--intro .section__inner {
  position: relative;
  z-index: 2;
  min-height: 100vh;
  padding-top: 140px;
}
.section__block--intro .flex__column {
  flex: 1 1 50%;
}
.section__block--intro {
  background-image: url(./img/back2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.section__block--intro .text-block {
  background-color: rgba(255, 255, 255, 0.80);
  padding: 30px;
  border-radius: 30px;
}
.section__block--intro h1 {
  color: #FF0043;
  font-size: 38px;
  line-height: 48px;
  font-weight: bold;
}
.section__block--intro h2 {
  font-size: 24px;
  line-height: 30px;
  font-family: 'Karla', sans-serif;
}
.section__block--intro { z-index: 5 }
.section__block--features { z-index: 4 }
.section__block--pricing { z-index: 3 }
.section__block--about-us { z-index: 2 }
.section__block--contact { z-index: 1 }

.section__block--features:before,
.section__block--pricing:before,
.section__block--about-us:before,
.section__block--contact:before {
  content: "";
  display: block;
  height: 110px;
  margin-top: -110px;
  visibility: hidden;
}
.section__inner {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 60px 20px 100px 20px;
}
.video-holder {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  box-shadow: 0px 10px 51px -19px rgba(0, 0, 0, 0.34);
  margin-left: auto;
  margin-right: auto;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.who-we-are .image-holder {
  width: 168px;
  height: 118px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
/* .who-we-are a {
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
} */
.who-we-are .image-holder img {
  display: block;
  max-height: 100%;
  object-fit: contain;
}
.footer__inner-block {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 15px 0;
}
.footer__menu-holder {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}
.footer__menu {
  width: 100%;
  list-style-type: none;
  padding-left: 0;
}
.footer__menu-item {
  margin: 10px 20px;
}
.footer__menu-link {
  color: black;
  font-size: 18px;
  text-decoration: none;
}
.footer__menu-link:hover {
  color: #568a99;
}
.footer__upper .footer__inner-block {
  padding: 50px 20px 20px 20px;
}
.footer__lower {
  background: linear-gradient(135deg, #ff0043ff 0%, #00cfc5ff 100%);
  /* background: linear-gradient(135deg, #ff0043, #a90731 40%, #00cfc5 100%); */
  text-align: center;
  color: #e4e5e6;
}
.footer__co {
  color: gray;
  padding: 20px;
  font-size: 14px;
  text-align: center;
}
.footer__co-text {
  font-size: 11px;
  line-height: 13px;
  text-align: left;
}
.footer__co-img {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
}
.footer p {
  color: gray;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  max-width: 270px;
}
.footer__logo {
  display: block;
  width: 90px;
  height: 90px;
  margin-bottom: 20px;
  position: relative;
}
.footer__logo svg {
  width: 100%;
}
.mb-1 {
  margin-bottom: 5px;
}
.mb-2 {
  margin-bottom: 10px;
}
.mb-3 {
  margin-bottom: 20px;
}
.mb-4 {
  margin-bottom: 40px;
}
.mb-5 {
  margin-bottom: 80px;
}
.mt-1 {
  margin-top: 5px;
}
.mt-2 {
  margin-top: 10px;
}
.mt-3 {
  margin-top: 20px;
}
.mt-4 {
  margin-top: 40px;
}
.mt-5 {
  margin-top: 80px;
}
.ml-1 {
  margin-left: 5px;
}
.ml-2 {
  margin-left: 10px;
}
.ml-3 {
  margin-left: 20px;
}
.ml-4 {
  margin-left: 40px;
}
.ml-5 {
  margin-left: 80px;
}
.mr-1 {
  margin-right: 5px;
}
.mr-2 {
  margin-right: 10px;
}
.mr-3 {
  margin-right: 20px;
}
.mr-4 {
  margin-right: 40px;
}
.mr-5 {
  margin-right: 80px;
}
.pb-1 {
  padding-bottom: 5px;
}
.pb-2 {
  padding-bottom: 10px;
}
.pb-3 {
  padding-bottom: 20px;
}
.pb-4 {
  padding-bottom: 40px;
}
.pb-5 {
  padding-bottom: 80px;
}
.pt-1 {
  padding-top: 5px;
}
.pt-2 {
  padding-top: 10px;
}
.pt-3 {
  padding-top: 20px;
}
.pt-4 {
  padding-top: 40px;
}
.pt-5 {
  padding-top: 80px;
}
.pl-1 {
  padding-left: 5px;
}
.pl-2 {
  padding-left: 10px;
}
.pl-3 {
  padding-left: 20px;
}
.pl-4 {
  padding-left: 40px;
}
.pl-5 {
  padding-left: 80px;
}
.pr-1 {
  padding-right: 5px;
}
.pr-2 {
  padding-right: 10px;
}
.pr-3 {
  padding-right: 20px;
}
.pr-4 {
  padding-right: 40px;
}
.pr-5 {
  padding-right: 80px;
}
.svgfill__white {
  fill: white;
}
.text-centered {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-color-red {
  color: #FF0043;
}
.text-color-dark-gray {
  color: #424242;
}
strong {
  font-weight: bold;
}
.flex {
  display: flex;
}
.flex-hc {
  justify-content: center;
}
.flex-vc {
  align-items: center;
}
.flex-up {
  align-items: flex-start;
}
.flex--space-around {
  justify-content: space-around;
}
.flex__50-50 > .flex__column {
  flex: 1 1 50%;
  width: 50%;
}
.flex__33-33-33 > .flex__column {
  flex: 1 1 30%;
  width: 50%;
}
.fluid-img {
  display: block;
  width: 100%;
  max-width: 100%;
}
.img-1 {
  width: 100%;
  max-width: 519px;
}
.button {
  display: inline-block;
  background-color: #00CFC5;
  color: white;
  font-family: 'Karla', sans-serif;
  text-transform: uppercase;
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 28px -19px rgba(0, 0, 0, 0.34);
  cursor: pointer;
  text-decoration: none;
}
.button--small {
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 5px;
}
.button:hover {
  background-color: #FF0043;
}
.hint__show {
  border: none;
  background-color: transparent;
  width: 50px;
  height: 50px;
  box-shadow: none;
  padding: 0;
  border-radius: 0;

  display: inline-block;
  box-shadow: 0px 10px 28px -19px rgba(0, 0, 0, 0.34);
}
.hint__show svg {
  fill: #00CFC5;
  transition: all 0.3s ease-in-out;
}
.button-soc {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.button-soc svg {
  fill: gray;
}
.max {
  margin-left: auto;
  margin-right: auto;
}
.max-300 {
  max-width: 300px;
}
.max-520 {
  max-width: 520px;
}
.max-650 {
  max-width: 650px;
}
.fk {
  font-family: 'Karla', sans-serif;
  text-transform: uppercase;
}
.cb {
  color: #00CFC5;
}
.border-right {
  border-right: 1px solid gray;
}
.border-left {
  border-left: 1px solid gray;
}
.border-green {
  border-color: #00CFC5;
}
.border-light-gray {
  border-color: #e2e2e2;
}

.formated-txt p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
}

.formated-txt h1 {
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 40px;
}

.formated-txt h2 {
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 20px;
}

.formated-txt h3 {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 20px;
}

.formated-txt ul {
  padding-left: 50px;
  margin-bottom: 20px;
}

@media screen and (max-width: 780px) {
  #pricing .section__inner {
    padding-bottom: 190px;
  }
}

@media screen and (max-width: 730px) {
  .footer .flex.flex__33-33-33 {
    flex-wrap: wrap;
  }

  .footer .flex.flex__33-33-33 .flex__column:nth-child(3) {
    margin-top: 30px;;
    flex: 1 0 100%;
    width: 100%;
    border-left: 0;
  }

  .footer__co {
    padding: 0;
  }
}

@media screen and (max-width: 720px) {
  .section__block--intro {
    margin-bottom: 0;
  }

  .section__block--intro .button__show {
    display: block;
    margin: 0 auto;
  }

  .section__block--intro:before {
    width: 100%;
    position: relative;
    min-height: 500px;
    display: block;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .section__block--intro .section__inner {
    padding-bottom: 20px;
  }

  .section__block--intro .flex__column:first-child {
    display: none;
  }

  .section__block--intro .flex__column.pl-4 {
    padding-left: 0 !important;
  }

  #features .flex.flex__50-50 {
    display: block;
    margin-bottom: 20px;
  }

  #features .flex.flex__50-50 .flex__column {
    width: 100%;
    padding: 0 !important;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 650px) {
  .who-we-are .flex.who {
    flex-direction: column;
  }

  .who-we-are .flex.who--regio p {
    text-align: left !important;
  }

  .who-we-are .flex.who--regio .flex__column:first-child {
    order: 2;
  }

  .who-we-are .flex.who--regio .flex__column:last-child {
    order: 1;
  }
}

@media screen and (max-width: 610px) {
  .price__holder {
    display: block;
  }
}

/**
* Hamburger
*/

.nav__hinput {
  display: none;
}

.nav__hamburger {
  display: none;
  width: 50px;
  height: 50px;
  top: 10px;
  right: 10px;
  z-index: 5;
  background-color: #ff0043;
  transition: background-color .3s ease-in-out;
  cursor: pointer;
  position: absolute;
}

.nav__label {
  display: none;
}

.nav__hline {
  transition: all .35s;
  background: #fff;
  width: 20px;
  height: 4px;
  display: block;
  top: 13px;
  left: 14px;
  position: absolute;
}

.nav__hline--middle {
  top: 22px;
}

.nav__hline--bottom {
  top: 32px;
}



@media screen and (max-width: 800px) {
  .section__block--features:before,
  .section__block--pricing:before,
  .section__block--about-us:before,
  .section__block--contact:before {
    height: 66px;
    margin-top: -66px;
  }

  .nav__hamburger {
      display: block;
  }

  .header, .header__inner {
    height: 66px;
  }

  .header__logo {
    width: 230px;
    height: 57px;
  }

  .header__slogan {
    display: none;
  }

  .header__ul {
    position: absolute;
    top: 66px;
    display: block;
    left: 0;
    background-color: rgba(255,255,255,0.9);
    width: 100%;
    overflow: hidden;
    max-height: 0;
    margin-top: 0;
    padding-left: 0;
  }

  .header__item {
    text-align: center;
    font-size: 18px;
  }
  .header__link {
    padding: 12px 10px;
  }
  .nav__hinput:checked~.nav__hamburger .nav__hline--top {
    transform: rotate(45deg);
    top: 1.375rem;
    left: .875rem;
  }

  .nav__hinput:checked~.nav__hamburger .nav__hline--middle {
    width: 0;
    left: 1.6875rem;
  }

  .nav__hinput:checked~.nav__hamburger .nav__hline--bottom {
    transform: rotate(135deg);
    top: 1.375rem;
    left: .875rem;
  }

  .tl-app-header-navi,
  .tl-app-header-languages {
    display: none;
  }

  .nav__hinput:checked ~.header__nav .header__ul {
    max-height: 500px;
  }

  .section__block--intro .section__inner {
    padding-top: 86px;
  }
}

@media screen and (max-width: 425px) {
  .footer .flex.flex__33-33-33 .flex__column:nth-child(2) {
    margin-top: 30px;
    flex: 1 0 100%;
    width: 100%;
    border-left: none;
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
  }

  .footer__menu {
    margin-bottom: 0;
  }

  .footer__menu-item {
    text-align: center;
  }

  .footer p {
    max-width: none;
  }
}

@media screen and (max-width: 380px) {
  .big-bullets {
    padding-left: 10px;
  }
}

.header__langitem {
    padding: 5px 3px;
    text-decoration: none;
}

.header__langcontainer {
	display: block;
	margin-top: 5px;
}
.header__langcontainer > * {
    margin: 1px 0;
}
