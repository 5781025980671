/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 *
 * Form.css
 */


.tl-form {
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 500px;
  margin: 50px auto 0 auto;
  padding: 0;
  font-family: 'Roboto Slab', serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #000;
}

.tl-form-disabled {
  opacity: 1;
}

.tl-form > * {
  display: block;
  box-sizing: border-box;
  position: relative;
  margin: 0;
  padding: 5px 0;
}

.tl-form-footer {
  margin-top: 20px;
}

.tl-form label,
.tl-form span {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}

.tl-form > * > label:nth-child(1),
.tl-form > * > span:nth-child(1) {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  width: 150px;
  height: 30px;
}

.tl-form input[type="text"],
.tl-form input[type="email"],
.tl-form input[type="password"],
.tl-form select {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  width: 350px;
  height: 30px;
  margin: 0;
  padding: 0 0 0 5px;
  background-color: #fff;
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  vertical-align: bottom;
  color: inherit;
}

.tl-form select {
  padding-left: 1px;
  line-height: 28px;
}

.tl-form select option {
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: 28px;
}

.tl-form input[type="text"]:focus,
.tl-form input[type="email"]:focus,
.tl-form input[type="password"]:focus,
.tl-form select:focus {
  border: solid 2px rgba(0, 0, 0, 1);
}

.tl-form-error input[type="text"],
.tl-form-error input[type="email"],
.tl-form-error input[type="password"],
.tl-form-error select {
  border-color: rgba(255, 129, 162, 0.8);
  background-color: rgba(255, 129, 162, 0.1);
}

.tl-form input[type="checkbox"] {
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: 3px;
  left: 0;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  opacity: 0;
}

.tl-form input[type="checkbox"] + span {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  padding: 0 0 0 35px;
  cursor: pointer;
}

.tl-form input[type="checkbox"] + span::before {
  display: block;
  content: '\2713';
  box-sizing: border-box;
  position: absolute;
  top: 3px;
  left: 0;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border: solid 1px rgba(0, 0, 0, 0.5);
  font-family: monospace;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  color: transparent;
}

.tl-form input[type="checkbox"]:focus + span::before {
  border: solid 2px rgba(0, 0, 0, 1);
  line-height: 20px;
}

.tl-form input[type="checkbox"]:checked + span::before {
  background-color: #6b5b8d;
  color: #fff;
}

.tl-form-error input[type="checkbox"] + span::before {
  border-color: rgba(255, 129, 162, 0.8);
  background-color: rgba(255, 129, 162, 0.1);
}

button,
a.button-link {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  height: 30px;
  margin: 0;
  padding: 0 30px;
  background-color: #eee;
  border: solid 1px #cccc;
  border-radius: 2px;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: #fff;
  cursor: pointer;
}

button[type="button"] {
  background-color: rgba(107, 91, 141, 0.5);
  border: solid 1px rgba(51, 42, 68, 0.5);
}

button[type="button"]:hover {
  background-color: #473e5f;
  border-color: #332a44;
}

button[type="button"]:active {
  background-color: #332a44;
  border-color: #332a44;
}

button[type="submit"] {
  background-color: #6b5b8d;
  border: solid 1px #332a44;
}

button[type="submit"]:hover {
  background-color: #473e5f;
  border-color: #332a44;
}

button[type="submit"]:active {
  background-color: #332a44;
  border-color: #332a44;
}

button:disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}


a.button-link {
  background-color: #6b5b8d;
  border: solid 1px #332a44;
  line-height: 28px;
}

a.button-link:hover {
  background-color: #473e5f;
  border-color: #332a44;
  text-decoration: none;
}

a.button-link:active {
  background-color: #332a44;
  border-color: #332a44;
}

a.button-link-disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

@media screen and (max-width: 650px) {
  .tl-form {
    width: 100%;
  }

  .tl-form input[type=text],
  .tl-form input[type=password],
  .tl-form input[type=email],
  .tl-form select {
    width: 100%;
  }

  .tl-form-branch {
    display: flex;
    justify-content: center;
  }
  .tl-form-branch--left {
    justify-content: flex-start;
  }

  .tl-form-buffer {
    display: none !important;
  }
}
